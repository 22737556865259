<template>
  <fb-page>
    <!-- 头部插槽 -->
    <fb-header slot="header"
               :title="title"
               fixed>
      <fb-button slot="left"
                 icon="back"
                 @click="$router.back()"
                 size="small"
                 type="primary">
        返回
      </fb-button>
    </fb-header>
    <div class="oa-billing-record">
      <div class="oa-invoice-list">
        <div class="oa-invoice-box">
          <div class="oa-invoice">
            <div class="oa-invoice-title">开票日期</div>
            <div class="oa-invoice-content">2019.12.3</div>
          </div>
          <div class="oa-invoice">
            <div class="oa-invoice-title">发票抬头</div>
            <div class="oa-invoice-content">2019.12.3</div>
          </div>
          <div class="oa-invoice">
            <div class="oa-invoice-title">开票金额</div>
            <div class="oa-invoice-content">2019.12.3</div>
          </div>
          <div class="oa-invoice">
            <div class="oa-invoice-title">开票项目</div>
            <div class="oa-invoice-content">2019.12.3</div>
          </div>
          <div class="oa-invoice">
            <div class="oa-invoice-title">发票类型</div>
            <div class="oa-invoice-content">2019.12.3</div>
          </div>
        </div>
      </div>
    </div>
  </fb-page>
</template>

<script>
export default {
  name: 'oa-billing-record',
  components: {},
  data() {
    return {
      title: '开票记录'
    }
  },
  computed: {},
  methods: {
    // 获取开票记录
    // getInvoice() {}
  },
  created() {},
  mounted() {}
}
</script>

<style lang='stylus'>
.oa-billing-record
  position relative
  & .oa-invoice-box
    background-color rgba(242, 242, 242, 1)
    border 1px solid rgba(204, 204, 204, 1)
    border-radius 5px
    padding 10px 20px
    box-sizing border-box
    margin-top 10px
    & .oa-invoice
      display flex
      flex-direction row
      align-items center
      justify-content space-between
      padding 2px 0
      box-sizing border-box
      & .oa-invoice-content
        flex 1
        margin-left 10px
        font-size 14px
        text-align right
      & .oa-invoice-title
        font-size 14px
</style>
