var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fb-page",
    [
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: _vm.title, fixed: "" },
          slot: "header",
        },
        [
          _c(
            "fb-button",
            {
              attrs: {
                slot: "left",
                icon: "back",
                size: "small",
                type: "primary",
              },
              on: {
                click: function ($event) {
                  return _vm.$router.back()
                },
              },
              slot: "left",
            },
            [_vm._v(" 返回 ")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "oa-billing-record" }, [
        _c("div", { staticClass: "oa-invoice-list" }, [
          _c("div", { staticClass: "oa-invoice-box" }, [
            _c("div", { staticClass: "oa-invoice" }, [
              _c("div", { staticClass: "oa-invoice-title" }, [
                _vm._v("开票日期"),
              ]),
              _c("div", { staticClass: "oa-invoice-content" }, [
                _vm._v("2019.12.3"),
              ]),
            ]),
            _c("div", { staticClass: "oa-invoice" }, [
              _c("div", { staticClass: "oa-invoice-title" }, [
                _vm._v("发票抬头"),
              ]),
              _c("div", { staticClass: "oa-invoice-content" }, [
                _vm._v("2019.12.3"),
              ]),
            ]),
            _c("div", { staticClass: "oa-invoice" }, [
              _c("div", { staticClass: "oa-invoice-title" }, [
                _vm._v("开票金额"),
              ]),
              _c("div", { staticClass: "oa-invoice-content" }, [
                _vm._v("2019.12.3"),
              ]),
            ]),
            _c("div", { staticClass: "oa-invoice" }, [
              _c("div", { staticClass: "oa-invoice-title" }, [
                _vm._v("开票项目"),
              ]),
              _c("div", { staticClass: "oa-invoice-content" }, [
                _vm._v("2019.12.3"),
              ]),
            ]),
            _c("div", { staticClass: "oa-invoice" }, [
              _c("div", { staticClass: "oa-invoice-title" }, [
                _vm._v("发票类型"),
              ]),
              _c("div", { staticClass: "oa-invoice-content" }, [
                _vm._v("2019.12.3"),
              ]),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }